import { gtmTrackEvent, gtmTrackEventError } from './gtmTrackEvent';

export interface PageViewEvent {
  page?: {
    pageType?: string;
    page_location?: string;
    page_title?: string;
    page_referrer?: string;
    page_path?: string;
    timestamp?: string;
  };
  blog?: {
    blog_title?: string;
    blog_author?: string;
    blog_category?: string;
    blog_publish_date?: string;
  };
  user?: {
    customer_type?: string;
    hashed_user_id?: string;
    first_order?: boolean;
    account_type?: string;
    email?: string;
    hashed_email?: string;
    login_status?: boolean;
  };
}
export const gtmPageView = (event: PageViewEvent) => {
  const eventKey = 'page_view';
  try {
    const eventObject = {
      event: eventKey,
      ...event,
    };
    gtmTrackEvent(eventKey, eventObject, true);
  } catch (error) {
    gtmTrackEventError(error, eventKey);
  }
};
